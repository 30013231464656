.Features {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.Features-Section {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
.Features-Image-Section {
  flex: 1;
  position: relative;
}
.Features-Image-Section {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Features-Card-Section {
  flex: 1;
}
.IMG1 {
  width: 55vmin;
}
.IMG2 {
  width: 30vmin;
  position: absolute;
  bottom: -12%;
  right: 10%;
}

.Features-Card-Section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* margin-right: 10vmin; */
}
.Card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 20vmin;
}
.Card-Title {
  display: flex;
  align-items: center;
  word-wrap: break-word;
  padding-right: 2vmin;
  margin-bottom: 1vmin;
}
.Card-Heading {
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vmin;
  line-height: 3vmin;
  letter-spacing: 0.01vmin;
  padding-left: 2vmin;
}
.Card-Content {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6vmin;
  line-height: 2.4vmin;

  letter-spacing: 0.02vmin;
}
.Card-Title > img {
  width: 3vmin;
}

@media (width < 1068px) {
  .Features-Section {
    flex-direction: column;
  }
  .Features-Card-Section {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: goldenrod; */
    padding-top: 4vmax;
  }
  .Card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: gray; */
    /* width: max-content; */
    padding: 2vmax 0vmax;
  }
  .Card-Content {
    font-size: 1.6vmax;
    line-height: 2.4vmax;
    /* background: greenyellow; */
    letter-spacing: 0.02vmax;
    align-items: center;
    width: 50%;
  }
  .Card-Heading {
    font-style: normal;
    font-weight: 500;
    font-size: 2.5vmax;
    line-height: 3vmax;
    letter-spacing: 0.01vmax;
  }
  .Card-Title > img {
    width: 3vmax;
  }
}
