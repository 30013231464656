.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vmin 3.6vmin;
  width: calc(12vw + 10vmin);
  border: 1px solid;
  filter: drop-shadow(0px 4px 31px rgba(0, 0, 0, 0.15));
  font-style: normal;
  font-weight: 500;
  font-size: calc(1vw + 2px);
  line-height: 2.5vmin;

  letter-spacing: 0.02vmin;
}
@media (width < 1068px) {
  .Button {
    font-size: 1.5vmax;
  }
}
@media (height < 350px) {
  .Button {
    height: 15vmin;
  }
}
