.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 75vmin;
  margin-bottom: 3rem;
}
.Header {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.Header-Title {
  font-style: normal;
  font-weight: 500;
  font-size: 8vmin;
  line-height: 8.8vmin;
  text-align: center;
  letter-spacing: 0.02vmin;
  width: 70vmin;

  word-wrap: break-word;
}
.Header-content {
  padding-top: 1vmin;
  font-style: normal;
  font-weight: normal;
  font-size: 2vmin;
  line-height: 3vmin;
  word-wrap: break-word;
  width: 40vw;
  text-align: center;
  letter-spacing: 0.02vmin;
}
.Home-Btns {
  display: flex;
  width: 30vmax;
  justify-content: space-between;
  gap: 1rem;
}
.Home-Image-Container {
  position: relative;
  height: 90vmin;
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* mix-blend-mode: color-dodge; */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0e0e0e 66.15%);
  /* background-color: aquamarine; */
}

.Home-Image1 {
  /* border: 10px solid gray; */
  /* position: absolute; */
  /* position: relative; */
  width: 55vmin;
  height: 45vmin;
  left: 35%;
  background: url("../../Assets/Dashboard1.png");
  background-size: 100%;
  transform: matrix(0.71, -0.54, 0.93, 0.71, 0, 0);
  /* z-index: -1; */
}
.Home-Image2 {
  position: absolute;
  width: 55vmin;
  height: 45vmin;
  top: 0;
  left: -110%;
  background: url("../../Assets/Boards.png");
  background-size: 100%;
}
.Home-Image3 {
  position: absolute;
  width: 55vmin;
  height: 45vmin;

  top: 110%;

  background: url("../../Assets/ChatBot.png");
  background-size: 100%;
}

@media (width < 1068px) {
  .Home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background: gold; */
    margin-bottom: 0rem;
  }
  .Header-content {
    padding-top: 1vmin;
    font-style: normal;
    font-weight: normal;
    font-size: 2vmax;
    line-height: 3vmax;
    word-wrap: break-word;
    width: 50vmax;
    text-align: center;
    letter-spacing: 0.02vmax;
  }
  .Home-Btns {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .Home-Image1 {
    width: 35vmin;
    height: 25vmin;
  }
  .Home-Image2 {
    width: 35vmin;
    height: 25vmin;
    top: 0;
    left: -110%;
  }
  .Home-Image3 {
    width: 35vmin;
    height: 25vmin;

    top: 110%;
  }
  .Home-Image-Container {
    overflow: hidden;
    align-items: center;
    height: 60vmin;
  }
}
@media (height < 600px) {
  .Header-content {
    padding-top: 1vmin;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 4rem;
    word-wrap: break-word;
    width: 50vmax;
    text-align: center;
    letter-spacing: 0.2vmin;
  }
  .Header-Title {
    font-style: normal;
    font-weight: 500;
    font-size: 4vmax;
    line-height: 5.6rem;
    text-align: center;
    letter-spacing: 0.02vmax;
    width: 35vmax;

    word-wrap: break-word;
  }
  .Home {
    height: 40vmax;
  }
}
